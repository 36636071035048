import {PaymentDetails} from '../PaymentDetails'
import {MrPolicy} from './MrPolicy'
import {Pence} from '../../some-types'

import {MrPlan} from './MrPlan'
import Required from '../../validation/validators/Required'

import {Type} from 'class-transformer'
import {
    displayableId,
    getNextAnniversaryOf,
    getNextPaymentDateAfter,
    KeyMapped,
    values,
} from '@peachy/utility-kit-pure'
import {startOfTomorrow} from 'date-fns'
import {HasLifecycleStatus, isCancelled, LifecycleStatus, TransferDetails} from './LifecycleStatus'
import {MrLife} from './MrLife'


export const defaultTimezone = 'Europe/London'

export class MrSubscription implements HasLifecycleStatus {

    @Required()
    id: string

    versionIdx?: number
    versionDate?: number

    @Required()
    startDate: number

    status: LifecycleStatus
    endDate?: number
    cancellationReason?:string
    nextRenewalDate?: number
    lastRenewalDate?: number

    transfer?: TransferDetails

    timezone: string = defaultTimezone

    billingAnchor?: number // integer day of month

    totalMonthlyPremium?: Pence

    stripeSubscriptionId?: string
    stripeProductId?: string

    plans: KeyMapped<MrPlan> = {}

    policies: KeyMapped<MrPolicy> = {}

    @Required()
    name: string

    @Required()
    contactName: string

    @Required()
    contactEmail: string

    @Required()
    @Type(() => PaymentDetails)
    paymentDetails?: PaymentDetails

    distributorId?: string

    referenceNumber?(): string {
        return displayableId(this.id)
    }

    getNextPaymentDate?(): number {
        return getNextPaymentDateAfter(new Date(this.startDate), startOfTomorrow()).getTime()
    }

    getAnnualRenewalDate?(): Date {
        return getNextAnniversaryOf(new Date(this.startDate))
    }


    getActivePolicies?(): MrPolicy[] {
        return values(this.policies).filter(p => !isCancelled(p))
    }


    findPolicy? = (where: (policy: MrPolicy) => boolean) =>
        values(this.policies).find(p => where(p))

    hasPolicy? = (policyId: string) =>
        !!this.policies[policyId]



    resolveLife? = (lifeId: string) =>
        this.findPolicy(p => lifeId in p.lives)?.lives[lifeId]

    resolvePlan? = (planId: string) =>
        this.plans[planId] ?? this.findPolicy(p => planId in p.plans)?.plans[planId]

    findLife? = (where: (life: MrLife) => boolean) =>
        values(this.policies).flatMap(p => values(p.lives)).find(l => where(l))

    findLifeAndPolicy?(where: (life: MrLife, policy: MrPolicy) => boolean): [MrLife, MrPolicy] {
        for(const policy of values(this.policies)) {
            for(const life of values(policy.lives)) {
                if (where(life, policy)) return [life, policy]
            }
        }
        return [null, null]
    }

    resolveLifeAndPolicyByEmail? = (email: string): [MrLife, MrPolicy] =>
        this.findLifeAndPolicy((l) => l.email === email )
    resolveLifeAndPolicy? = (lifeId: string): [MrLife, MrPolicy] =>
        this.findLifeAndPolicy((l) => l.id === lifeId )
}
