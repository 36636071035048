import {isCancelled, MrSubscription} from '@peachy/core-domain-pure'

import {isNullish, keys, mapById, unique} from '@peachy/utility-kit-pure'
import {reconcilePolicyModelAlteration} from './reconcilePolicyModelAlteration'
import {cancelSubscription, ensureCancellationStatus} from './valid-alterations/cancellation'
import {reactivateSubscription} from './valid-alterations/reactivation'
import {latestOf, pruneUndefined} from '../alteration-kit/loose-end-kit'
import {transferInSubscription, transferOutSubscription} from './valid-alterations/transfer'


export function reconcileSubscriptionModelAlteration(
    currentSubscription: MrSubscription,
    alteredSubscription: MrSubscription,
    effectiveDate: number
): MrSubscription {
    assertAreCompatible(currentSubscription, alteredSubscription)

    // subscription added?
    if (!currentSubscription) {
        if (alteredSubscription?.transfer?.in) {
            alteredSubscription = transferInSubscription(
                alteredSubscription,
                alteredSubscription.transfer.in.from,
                alteredSubscription.transfer.in.date ?? effectiveDate,
                alteredSubscription.transfer.in.reason ?? 'Subscription transferred in - no reason available'
            )
        } else {
            return reconcileNewSubscription(alteredSubscription, effectiveDate)
        }
    }

    if (!currentSubscription.transfer?.out && alteredSubscription?.transfer?.out) {
        return transferOutSubscription(
            currentSubscription,
            alteredSubscription.transfer.out.to,
            alteredSubscription.transfer.out.date ?? effectiveDate,
            alteredSubscription.transfer.out.reason ?? 'Subscription transferred out - no reason available',
        )
    }



    // subscription removed or cancelled?
    if (!alteredSubscription || alteredSubscription.status === 'CANCELLED') {

        console.log('subscription removed or cancelled?')

        const endDate = alteredSubscription?.endDate ?? effectiveDate
        return cancelSubscription(
            currentSubscription,
            endDate,
            alteredSubscription?.cancellationReason ?? 'Subscription cancelled - no reason available'
        )
    }

    // reactivate subscription?
    if (currentSubscription.status === 'CANCELLED') {
        alteredSubscription = reactivateSubscription(alteredSubscription, effectiveDate)
    }

    const reconciledPolicies = reconcileSubscriptionPolicies(currentSubscription, alteredSubscription, effectiveDate)

    // clear premium if any policies need quoting
    if (reconciledPolicies.some(policy => isNullish(policy.totalMonthlyPremium))) {
        delete alteredSubscription.totalMonthlyPremium
    }

    // cancel sub if all policies are cancelled
    if (reconciledPolicies.every(isCancelled)) {
        ensureCancellationStatus(alteredSubscription, effectiveDate,'Automatic cancellation due to all policies being cancelled')
    }

    // return reconciled subscription
    alteredSubscription.policies = mapById(reconciledPolicies)
    pruneUndefined(alteredSubscription)
    return alteredSubscription
}





function assertAreCompatible(
    currentSubscription: MrSubscription,
    alteredSubscription: MrSubscription,

) {
    if (currentSubscription && alteredSubscription) {
        if (currentSubscription.id !== alteredSubscription.id) {
            throw `Cannot alter subscription id from ${currentSubscription.id} to ${alteredSubscription.id}`
        }
    }
}


export function reconcileNewSubscription(
    newSub: MrSubscription,
    effectiveDate: number
): MrSubscription {

    const subEffectiveDate = latestOf(effectiveDate, newSub.startDate)
    const reconciledPolicies = reconcileSubscriptionPolicies(null, newSub, subEffectiveDate)

    return {
        ...newSub,
        status: 'ACTIVE',
        versionIdx: 1,
        versionDate: effectiveDate,
        startDate: subEffectiveDate,
        totalMonthlyPremium: undefined,
        policies: mapById(reconciledPolicies)
    }
}



function reconcileSubscriptionPolicies(
    currentSubscription: MrSubscription,
    alteredSubscription: MrSubscription,
    effectiveDate: number
) {

    const currentPolicies = currentSubscription?.policies ?? {}

    const policyIds = unique([...keys(currentPolicies), ...keys(alteredSubscription.policies)])
    return policyIds.map(policyId => {
        const currentPolicy = currentPolicies[policyId]
        const alteredPolicy = alteredSubscription?.policies?.[policyId]
        return reconcilePolicyModelAlteration(currentPolicy, alteredPolicy, alteredSubscription.plans, effectiveDate)
    })
}
