import {isCancelled, MrPlan, MrPolicy} from '@peachy/core-domain-pure'

import {isNullish, KeyMapped, keys, mapById, unique} from '@peachy/utility-kit-pure'
import {cancelPolicy, ensureCancellationStatus} from './valid-alterations/cancellation'
import {reactivatePolicy} from './valid-alterations/reactivation'
import {reconcileLifeModelAlteration} from './reconcileLifeModelAlteration'
import {latestOf, pruneUndefined} from '../alteration-kit/loose-end-kit'
import {transferInPolicy, transferOutPolicy} from './valid-alterations/transfer'


export function reconcilePolicyModelAlteration(
    currentPolicy: MrPolicy,
    alteredPolicy: MrPolicy,
    plans: KeyMapped<MrPlan>,
    effectiveDate: number
): MrPolicy {
    assertAreCompatible(currentPolicy, alteredPolicy)

    const policyEffectiveDate = latestOf(effectiveDate, alteredPolicy?.startDate ?? currentPolicy?.startDate)

    // policy added?
    if (!currentPolicy) {
        if (alteredPolicy.transfer?.in) {
            alteredPolicy = transferInPolicy(
                alteredPolicy,
                alteredPolicy.transfer.in.from,
                alteredPolicy.transfer.in.date ?? effectiveDate,
                alteredPolicy.transfer.in.reason ?? 'Policy transferred in - no reason available')
        } else {
            return reconcileNewPolicy(alteredPolicy, plans, policyEffectiveDate)
        }
    }

    // policy transferred out?
    if (!currentPolicy.transfer?.out && alteredPolicy?.transfer?.out) {
        return transferOutPolicy(
            currentPolicy,
            alteredPolicy.transfer.out.to,
            alteredPolicy.transfer.out.date ?? effectiveDate,
            alteredPolicy.transfer.out.reason ?? 'Policy transferred out - no reason available',
        )
    }

    // policy removed or cancelled?
    if (!alteredPolicy || alteredPolicy.status === 'CANCELLED' && !alteredPolicy.transfer?.out) {
        const endDate = alteredPolicy?.endDate ?? policyEffectiveDate
        return cancelPolicy(currentPolicy, endDate,
            alteredPolicy?.cancellationReason ?? 'Policy cancelled - no reason available')
    }

    // reactivate policy?
    if (currentPolicy.status === 'CANCELLED') {
        alteredPolicy = reactivatePolicy(alteredPolicy, policyEffectiveDate)
    }

    const reconciledLives = reconcilePolicyLives(currentPolicy, alteredPolicy, plans, policyEffectiveDate)

    // clear premium if any lives need quoting
    if (reconciledLives.some(life => isNullish(life.totalMonthlyPremium))) {
        delete alteredPolicy.totalMonthlyPremium
    }

    // cancel policy if all lives are cancelled
    if (reconciledLives.every(isCancelled)) {
        ensureCancellationStatus(alteredPolicy, policyEffectiveDate, 'Automatic cancellation due to all lives being cancelled')
    }

    // return reconciled policy
    pruneUndefined(alteredPolicy)
    alteredPolicy.lives = mapById(reconciledLives)
    return alteredPolicy
}



function assertAreCompatible(
    currentPolicy: MrPolicy,
    alteredPolicy: MrPolicy,
) {
    if (currentPolicy && alteredPolicy) {
        if (currentPolicy.id !== alteredPolicy.id) {
            throw `Cannot alter life id from ${currentPolicy.id} to ${alteredPolicy.id}`
        }
    }
}


export function reconcileNewPolicy(
    newPolicy: MrPolicy,
    plans: KeyMapped<MrPlan>,
    effectiveDate: number
): MrPolicy {

    const reconciledLives = reconcilePolicyLives(null, newPolicy, plans, effectiveDate)

    return {
        ...newPolicy,
        status: 'ACTIVE',
        startDate: effectiveDate,
        totalMonthlyPremium: undefined,
        lives: mapById(reconciledLives)
    }
}


function reconcilePolicyLives(
    currentPolicy: MrPolicy,
    alteredPolicy: MrPolicy,
    plans: KeyMapped<MrPlan>,
    effectiveDate: number
) {
    const currentLives = currentPolicy?.lives ?? {}

    const lifeIds = unique([...keys(currentLives), ...keys(alteredPolicy.lives)])

    return lifeIds.map(lifeId => {
        const currentLife = currentPolicy?.lives?.[lifeId]
        const alteredLife = alteredPolicy?.lives?.[lifeId]
        const planId = alteredLife ? alteredLife.planId : currentLife.planId
        return reconcileLifeModelAlteration(currentLife, alteredLife, plans?.[planId], effectiveDate)
    })
}
